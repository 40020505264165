import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // ... other theme options
        defaultFont: 'europa, sans-serif',
      },
});
